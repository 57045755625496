<template>
<div class="flex-grow-1 d-flex align-items-center justify-content-center">
  <div v-if="activated && !loading" class="d-flex flex-column align-items-center">
    <img src="@/assets/illustrations/success_illustration.svg" width="400" class="mb-5" alt="">

    <p class="h4">
      Account <span class="text-success"> activated! </span>
    </p>

    <router-link class="btn button-gradient-primary" to="/login">
      Go to login
    </router-link>
  </div>

  <div v-else-if="loading" class="d-flex flex-column align-items-center">
    <b-spinner variant="primary" class="mb-4" style="width: 70px; height: 70px;" type="grow" label="Spinning"></b-spinner>
    <p class="h4">
      Trying to activate your account...
    </p>
  </div>

  <div v-else-if="!activated && !loading" class="d-flex flex-column align-items-center">
    <img src="@/assets/illustrations/error_illustration.svg" width="400" class="mb-5" alt="">

    <p class="h4 mb-4">
      Something went <span class="text-danger">wrong!</span>
    </p>

    <b-btn variant="primary" to="/login" @click="activeAccount" class="button-gradient-primary">
      Retry
    </b-btn>
  </div>
</div>
</template>

<script>
export default {
  name: "account_activate",

  data: () => ({
    loading: true,
    activated: false,
    query_token: null,
  }),

  methods: {
    activeAccount() {
      this.axios('activate-account', {
        method: 'POST',
        data: this.query_token
      })
          .then(response => {
            this.activated = true
            this.loading = false
          })
          .catch(error => {
            this.activated = false
            this.loading = false
          })
    },
  },

  mounted() {
    this.query_token = this.$route.query.token

    this.activeAccount()
  }
}
</script>

<style scoped>

</style>